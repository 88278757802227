import { PATHNAMES } from '$api/constants';
import { buildURL, getClientId, getLocale } from '../shared/helpers';

function createLink(url: URL) {
  const link = document.createElement('link');

  link.setAttribute('rel', 'preload');
  link.setAttribute('crossorigin', '');
  link.setAttribute('href', url.toString());
  link.setAttribute('as', 'fetch');

  return link;
}

const socialProviderUrl = buildURL(PATHNAMES.socialProviders);
socialProviderUrl.searchParams.set('client_id', getClientId());

const localeUrl = new URL(
  `locales/${getLocale()}.json`,
  window.location.origin
);

const headElement = document.querySelector('head');

if (headElement) {
  headElement.appendChild(createLink(socialProviderUrl));
  headElement.appendChild(createLink(localeUrl));
}
