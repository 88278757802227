import { sanitizeLocale } from '$locales/helpers';
import { PostMessagePort } from '$scripts/events/events';
import { ERROR_REASONS, envs, urls } from '@sentry/shared';
import { z } from 'zod';
import { getLoggers } from './loggers';

const { getApiUrl } = urls.create(envs.getAppEnv);

export function buildURL(pathname: string) {
  const apiUrl = getApiUrl();

  if (apiUrl) {
    return new URL(pathname, apiUrl);
  }

  throw new Error('Unexpected void value for API URL');
}

export function getClientId() {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get('client_id') ?? '';
}

export function getLocale() {
  const searchParams = new URLSearchParams(window.location.search);

  return sanitizeLocale(searchParams.get('locale') ?? '');
}

export function setSentry(slice: Record<string, any>) {
  const { __sentry__ } = window;
  window.__sentry__ = {
    ...(__sentry__ || {}),
    ...slice,
  };
}

export function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function getRelay() {
  if (window.__sentry__ && window.__sentry__.relay) {
    return window.__sentry__.relay;
  }

  let relay = '';

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('relay')) {
    relay = searchParams.get('relay') as string;
  } else {
    relay = generateRelay();
  }

  setSentry({ relay });

  return relay;
}

export function generateRelay(): string {
  const randomBytes = new Uint8Array(16);
  crypto.getRandomValues(randomBytes);
  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40; // Set version to 4 (0100)
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80; // Set variant to 1 (10)

  const toHex = (n: number) => n.toString(16).padStart(2, '0');
  const byteToHex = Array.from(randomBytes, toHex);
  const relay = [
    ...byteToHex.slice(0, 4),
    '-',
    ...byteToHex.slice(4, 6),
    '-',
    ...byteToHex.slice(6, 8),
    '-',
    ...byteToHex.slice(8, 10),
    '-',
    ...byteToHex.slice(10),
  ].join('');

  return relay;
}

/**
 * = UME =
 * In the case of Unified Mobile Engineering, we're running wrapper less
 * which means that we're only using the webkit provided postMessage function
 * for communication.
 */
export function getPostMessagePort(
  platform: 'ios' | 'android'
): PostMessagePort {
  return platform === 'ios'
    ? window.webkit.messageHandlers.Sentry
    : window.Sentry;
}

export function logError(error: unknown) {
  const variant = document.location.hash.substring(1);
  const { logError: errorLogger } = getLoggers();
  if (error && typeof error === 'object' && error.toString) {
    errorLogger({ error, variant: variant ?? 'undefined' });
    console.error(error.toString());
  } else {
    errorLogger({ error: error as string, variant: variant ?? 'undefined' });
    console.error(error);
  }
}

export function logCritical(error: Error) {
  const variant = document.location.hash.substring(1);
  const { logCritical: criticalLogger } = getLoggers();

  criticalLogger({ error, variant: variant ?? 'undefined' });
  console.error(error.toString());
}

export function logInfo(info: string, meta?: any) {
  const variant = document.location.hash.substring(1);
  const { logInfo: infoLogger } = getLoggers();

  infoLogger({ info, meta, variant: variant ?? 'undefined' });
  console.info(info, meta);
}

export function responseToInfo(resp: Response) {
  return JSON.stringify({
    status: resp.status,
    statusText: resp.statusText,
  });
}

/**
 * Only use native methods here and nothing external.
 * We're trying to make sure this function can not generate any errors as it is the last resort call
 * for the client to take over control.
 */
export function broadcastLastResortError(error?: unknown) {
  const params = new URLSearchParams(document.location.search.substring(1));
  if (params.get('wrapper') === 'false') {
    getPostMessagePort(params.get('platform') as 'ios' | 'android').postMessage(
      JSON.stringify({
        side: 'proxy',
        name: 'on-error',
        data: {
          name: ERROR_REASONS.unrecoverable,
        },
      })
    );
  } else {
    window.parent.postMessage(
      JSON.stringify({
        side: 'app',
        name: 'error',
        data: {
          name: ERROR_REASONS.unrecoverable,
          ...(error instanceof Error
            ? { stack: error.stack, message: error.message, cause: error.cause }
            : {}),
        },
      }),
      params.get('asserted_origin') ?? '*'
    );
  }

  logError(error);
}

export type ObjectValues<T> = T[keyof T];

export function zodEnumFromObjKeys<K extends string>(
  obj: Record<K, any>
): z.ZodEnum<[K, ...K[]]> {
  const [firstKey, ...otherKeys] = Object.keys(obj) as K[];
  return z.enum([firstKey, ...otherKeys]);
}

export const capitalizeStr = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
