import { allLocales } from '../config';
import { Locale } from '../index';

const defaultLocalesByLanguage = ['en-us', 'es-es', 'fr-fr', 'sr-rs', 'zh-cn'];

export function sanitizeLocale(val: string) {
  // transforms fr_FR to fr-FR
  let updatedVal = val.replace('_', '-');

  //transforms fr-FR to fr-fr
  updatedVal = updatedVal.toLowerCase();

  if (allLocales.includes(updatedVal as Locale)) {
    return updatedVal as Locale;
  }

  // extracts fr from fr-jp
  const language = updatedVal.split('-')[0];

  // finds the default locale for the language
  const countryLocale =
    defaultLocalesByLanguage.find(l => l.startsWith(language)) ||
    allLocales.find(l => l.startsWith(language));

  if (countryLocale) {
    console.warn(
      `The provided locale is unsupported. Using '${countryLocale}' locale instead.`
    );
    return countryLocale as Locale;
  }

  // if nothing is found, return en-us
  console.warn(
    `The provided locale is unsupported. Using 'en-us' locale instead.`
  );
  return 'en-us' as Locale;
}
