import { IntlShape, createIntl, createIntlCache } from '@formatjs/intl';
import { createStore } from 'zustand';

export const localeStore = createStore<
  { intl: any; loading: boolean; loadingPromise: Promise<any> | null },
  []
>(() => ({
  intl: createIntl({ locale: 'en-us', messages: {} }),
  loading: true,
  loadingPromise: null,
}));

function initIntl({
  locale,
  messages,
}: {
  locale: string;
  messages: any;
}): Promise<IntlShape<string>> {
  return new Promise(resolve => {
    const intlCache = createIntlCache();
    const intl = createIntl({ locale, messages }, intlCache);
    resolve(intl);
  });
}

export async function initLocalisation(locale: string) {
  const loadingPromise = fetch(`/locales/${locale}.json`)
    .then(res => res.json())
    .then(messages => initIntl({ locale, messages }));

  localeStore.setState({ loadingPromise });

  const intl = await loadingPromise;

  localeStore.setState({ intl, loading: false });
}

export const i18nLoading = () => ({
  isLocaleLoading: localeStore.getState().loading,
  localeLoadingPromise: localeStore.getState().loadingPromise,
});

export const i18n = () => localeStore.getState().intl;
