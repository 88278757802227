import { LoggerFn, createLogger, envs } from '@sentry/shared';
import { getRelay } from './helpers';

function getClientId() {
  const searchParams = new URLSearchParams(window.location.search);
  const client_id =
    (window.__sentry__ &&
      window.__sentry__.queryState &&
      window.__sentry__.queryState.client_id) ||
    null;

  return client_id ?? searchParams.get('client_id') ?? '';
}

export function getLoggers(): {
  logAnalytics: LoggerFn;
  logCritical: LoggerFn;
  logError: LoggerFn;
  logInfo: LoggerFn;
} {
  const logAnalytics = createLogger(
    envs.getAppEnv,
    getClientId(),
    getRelay(),
    'app-analytics'
  );

  const logError = createLogger(
    envs.getAppEnv,
    getClientId(),
    getRelay(),
    'app-error'
  );

  const logCritical = createLogger(
    envs.getAppEnv,
    getClientId(),
    getRelay(),
    'app-critical'
  );

  const logInfo = createLogger(
    envs.getAppEnv,
    getClientId(),
    getRelay(),
    'app-info'
  );

  return { logAnalytics, logCritical, logError, logInfo };
}
